<template>
  <div class="interact">
    <div class="interact__inner">
      <card-flip v-for="card, index in cardContext" 
      :key="index" 
      ref="cardContext"
      :imgBackFaceUrl="`images/${card}.png`"
      :card="{index, value:card}"
      @onFlip="checkRule($event)" />
    </div>
  </div>
</template>

<script>
  import CardFlip from "./CardFlip.vue";
  export default {
    emits: ['onResult'],
    props: {
      cardContext: {
        type: Array,
        default: function(){
          return [];
        }
      }
    },
    components: {
      CardFlip,
    },
    data() {
      return {
        rules: [],
        results: [],
      }
    },
    methods: {
      checkRule(card) {
        this.rules.push(card);
        if (this.rules.length === 2 && this.rules[0].value == this.rules[1].value) { 
          this.$refs.cardContext[this.rules[0].index].onEnableDisableMode();
          this.$refs.cardContext[this.rules[1].index].onEnableDisableMode();
          this.rules = [];
          const disableElement = document.querySelectorAll('.card.disable');
          if (disableElement && disableElement.length === this.cardContext.length - 2) {
            setTimeout(() => {
              this.$emit('onResult');
            }, 600);
          } 
        }else if (this.rules.length === 2 && this.rules[0].value != this.rules[1].value) {
          setTimeout(() => {
            this.$refs.cardContext[this.rules[0].index].onFlipBackCard();
            this.$refs.cardContext[this.rules[1].index].onFlipBackCard();
            this.rules = [];
          }, 800);
        }else{
          return false;
        }
      }
    }
  }
</script>
<style scoped lang="css">
  .interact {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .interact__inner {
    width: 430px;
    margin: 0 auto;
  }
</style>


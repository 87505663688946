<template>
  <main-screen v-if="statusMatch === 'default'" @onStart="onHandleBeforeStart($event)"/>
  <interact-screen v-if="statusMatch === 'match'"
    :cardContext="settings.cardsContext"
    @onResult="onGetResult"
    @onStartAgain="onStartAgain($event)"
  />
  <result-screen v-if="statusMatch === 'result'" :timer="timer" :totalOfBlocks="settings.totalOfBlocks" @onStartAgain="onHandleBeforeStart($event)" />
</template>

<script>
import MainScreen from "./Components/MainScreen.vue"
import InteractScreen from "./Components/InteractScreen.vue"
import ResultScreen from "./Components/ResultScreen.vue"
import {shuffled} from "./utils/array"

export default {
  name: 'App',
  data() {
    return {
      statusMatch: 'default',
      settings: {
        totalOfBlocks: 0,
        cardsContext: [],
        startedAt: null,
      },
      timer: null,
    }
  },
  components: {
    MainScreen,
    InteractScreen,
    ResultScreen
  },
  methods: {
    onHandleBeforeStart(config) {
      this.settings.totalOfBlocks = config.totalOfBlocks;
      const firstCard = Array.from({length: this.settings.totalOfBlocks/2}, (_, i) => i + 1);
      const cards = [...firstCard, ...firstCard];
      this.settings.cardsContext = shuffled(shuffled(shuffled(cards)));
      this.settings.startedAt = new Date().getTime();
      this.statusMatch = 'match';
    },

    onGetResult() {
      this.timer = new Date().getTime() - this.settings.startedAt;
      this.statusMatch = 'result';
    },
    onStartAgain(config) {
      this.statusMatch = 'match';
      this.totalOfBlocks = config.totalOfBlocks
    }
  }
}
</script>

<template>
  <div class="screen">
    <div class="screen__inner">
      <h1>✨ Congratulations ✨</h1>
      <p>{{ Math.round(timer / 1000) }} seconds</p>
      <button @click="onStartAgain()">Start Again</button>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      timer: {
        type: Date
      },
      totalOfBlocks: {
        type: Number,
        default: 0
      }
    },
    methods: {
      onStartAgain() {
        this.$emit('onStartAgain', {totalOfBlocks: this.totalOfBlocks});
      }
    }
  }
</script>
<style scoped lang="css">
  .screen {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .screen__inner {
    text-align: center;
  }
  .screen__inner * {
    margin-bottom: 16px;
  }
  .screen__inner p{
    font-size: 20px;
  }
  .screen__inner button{
    padding: 10px 20px;
    background-color: var(--light);
    border: none;
    border-radius: 16px;
  }
</style>

<template>
  <div class="main">
    <div class="main__inner">
      <h1>POKE MEMORIES </h1>
      <p>Select mode to start game</p>
      <div class="action">
        <button @click="onStart(16)" >
          <span>4x4</span>
          <span>Easy</span>
        </button>
        <button @click="onStart(36)">
          <span>6x6</span>
          <span>Normal</span>
        </button>
        <button @click="onStart(64)">
          <span>8x8</span>
          <span>Hard</span>
        </button>
        <button @click="onStart(100)">
          <span>10x10</span>
          <span>Super Hard</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    emits: ['onStart'],
    methods: {
      onStart(totalOfBlocks){
        this.$emit("onStart", {totalOfBlocks});
      },
    },
  };
</script>
<style scoped lang="css">
  .main{
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--dark);
    color: var(--light);
  }
  .main h1, .main p{
    text-align: center;
    margin-bottom: 8px;
  }
  .main .action {
    display: flex;
    align-items: center;
    margin-top: 24px;
  }
  .main .action button {
    width: 100px;
    height: 120px;
    padding: 16px;
    text-wrap: wrap;
    margin: 0px 10px;
    background: #fff;
    border-radius: 16px;
    border: none;
    box-shadow: 0px 3px 9px 0px rgba(255, 255, 255, 0.8);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: var(--font);
    font-size: 20px;
  }
</style>
